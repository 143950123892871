export default {
  data() {
   	return {
      autoRequest: true,
      preUrl: '',
      title: '',
      queryForm: {
        page: 1,
        limit: 10,
        name: '',
        status: ''
      },
      total: 0,
	  list:[],
	  form:{},
      showSearch: false,
      loading: false,
      open: false,
      editable: [],// 修改状态
	  semester:{},
   	}
  },
  filters:{
	numToString(val){
		return val.toString()
	},
	stringToInt(val){
		return parseInt(val)
	}
  },
  mounted() {
    if (this.autoRequest) {
      this.getList()
    }
  },
  methods: {
    getList() {
      this.loading = true
      this.axios.get(`/manage/${this.preUrl}/list`, {
        params: this.queryForm
      }).then(res => {
        this.total = parseInt(res.total)
        this.list = res.data
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    handleAdd() {
      this.reset()
      this.title = '新增'
      this.open = true
    },
    handleEdit(row) {
      this.reset()
      this.open = true
      this.title = '编辑'
      this.form = JSON.parse(JSON.stringify(row))
    },
    handleDelete(id) {
      this.$confirm('是否要删除该信息？', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        this.axios.delete('/manage/'+this.preUrl + '/delete/' + id, {
    		  params: { }
    		}).then(res => {
          if (res.status) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.getList()
          }
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      })
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.sort) {
            this.form.sort = parseInt(this.form.sort)
          }
		  console.log(this.form)
          if (this.form.id === undefined) {
            this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
              if (res.status) {
                this.$message.success('添加成功')
                this.open = false
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
              if (res.status) {
                this.$message.success('修改成功')
                this.open = false
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        }
      })
    },
    query() {
      this.getList()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 重置按钮
    resetQuery(formName) {
	  this.queryForm = {
		  page: 1,
		  limit: 10,
		  keyword: '',
		  status: ''
	  }
      this.resetForm(formName)
      this.query()
    },
    reset() {
      if (this.$refs.org !== undefined) {
        this.$refs.org.setCheckedKeys([])
      }
      this.form = {
        status: 1
      }
      this.resetForm('form')
    },
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields()
      }
    },
    chooseImage() {
      this.app.chooseImage((res) => {
        this.form.id = res[0].id
        this.form.url = res[0].url
      })
    },
    // 图片预览
    viewImage(item, name = 'url') {
      this.app.previeImage(item[name])
    },
    // 修改状态
    changeStatus(item, name) {
      const status = item[name] === 1 ? 1 : 2
      const msg = status === 1 ? '启用' : '禁用'
      this.axios.post('/manage/'+this.preUrl + '/changeState', {
        id: item.id,
        name: name,
        value: status
      }, {
        token: true
      }).then(res => {
        if (res.status) {
          this.$message({
            message: msg + '成功',
            type: 'success'
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 修改其中一项值
    changeState(index, name, className) {
      this.editable = new Array(this.list.length)

      this.editable[index] = true

      this.saveItem = this.list[index]

      this.$set(this.editable, index, true)
      // 让input自动获取焦点
      this.$nextTick(function() {
        var editInputList = document.getElementsByClassName(className)
        const input = editInputList[0].children[0]
        input.focus()
        input.onblur = (e) => {
          this.changeItem(name, this.saveItem)
          this.$set(this.editable, index, false)
        }
      })
    },
    changeItem(name, item) {
      this.axios.post('/manage/'+this.preUrl + '/changeState', {
        id: item.id,
        name: name,
        value: item[name]
      }, {
        token: true
      }).then(res => {
        const result = res.data
        if (result.status) {
          // 成功后
        }
      })
    },
    // 格式化数据，递归将空的children置为undefined
    formatCascaderData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.formatCascaderData(data[i].children)
        }
      }
      return data
    },
    getBlob(url, params) {
      return new Promise((resolve, reject) => {
        this.axios
          .get(url, {
            params: params,
            responseType: 'arraybuffer'
          })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
	importSuccess(e){
		console.log(e)
		// 上传成功之后清除历史记录;否则只能上传一次
		this.$refs.upload.clearFiles()
	},
	importError(e){
		console.log(e)
		this.$refs.upload.clearFiles()
	},
	beforeImportUpload(file){
		console.log(file)
		  let fileArr = file.name.split('.')
		  let suffix = fileArr[fileArr.length - 1]
		  //只能导入.xls和.xlsx文件
		  if (!/(xls|xlsx)/i.test(suffix)) {
			this.$message('文件格式不正确')
			return false
		  }
		  return true
	},
	beforeUpload(){},
	getsemester(){
		this.axios.get('/manage/semester/semester').then(res=>{
			if(res.status){
				this.semester = res.data
			}
		})
	},
	handleSelectionChange(e){
		let ids = [];
		if(e.length > 0){
			e.forEach((item)=>{
				ids.push(item.id);
			})
		}
		this.ids = ids
	},
	handleAllDel(){
		this.$confirm('确定要删除吗？', '提示', {
		  confirmButtonText: '删除',
		  cancelButtonText: '取消',
		  type: 'warning'
		}).then(() => {
		  this.loading = true
		  this.axios.post('/manage/'+this.preUrl + '/dels',{
		       ids:this.ids
		    }).then(res => {
				if (res.status) {
				  this.$message({
					message: '删除成功',
					type: 'success'
				  })
				  this.getList()
				}
				this.loading = false
		  }).catch(err => {
		       this.loading = false
		  })
		})
	}
  }
}
